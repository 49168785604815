import { ParkingStatus } from "../enums/parking";

export const parkingStatusMap = {
  [ParkingStatus.Undefined]: 0,
  [ParkingStatus.NoCharge]: 1,
  [ParkingStatus.Exist]: 2,
  [ParkingStatus.Neighborhood]: 3,
  [ParkingStatus.NotAvailable]: 4,
  [ParkingStatus.None]: 9,
};

/** 駐車場ありとなるParkingStatusの値 */
export const isExistParkingValues = [parkingStatusMap.noCharge, parkingStatusMap.exist];
