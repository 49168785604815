const Labels = {
  building: {
    is_exist_elevator: "エレベーター",
    is_exist_parcel_box: "宅配ボックス",
    is_exist_bicycle_parking: "駐輪場あり",
    is_exist_motorcycle_storage: "バイク置場あり",
    is_exist_parking: "駐車場あり",
    is_designers_mansion: "デザイナーズ",
    is_condominium: "分譲マンション",
    is_anytime_garbage_out: "24時間ゴミ出し可",
    is_tower_mansion: "タワーマンション",
    is_low_rise_mansion: "低層マンション",
    is_shinchiku: "新築",
    is_chikuasa: "築浅",
    parking_status: {
      1: "駐車場付き・無料",
      2: "駐車場有（敷地内）",
      3: "近隣駐車場",
    },
  },
  room: {
    is_system_kitchen: "システムキッチン",
    is_hot_water_system: "給湯",
    is_counter_kitchen: "カウンターキッチン",
    is_disposer: "ディスポーザー",
    is_subscribed_security_service: "セキュリティ会社加入済",
    is_flooring: "フローリング",
    is_walk_in_closet: "ウォークインクローゼット",
    is_loft: "ロフト",
    is_cable_tv: "ケーブルテレビ",
    is_private_yard: "専用庭",
    is_balcony: "バルコニー",
    is_roof_balcony: "ルーフバルコニー",
    is_internet_available: "インターネット接続可",
    is_corner_room: "角部屋",
    is_floor_heating: "床暖房",
    is_only_electric_systems: "オール電化",
    is_no_charge_cable_tv: "ケーブルテレビ無料",
    is_closet: "クローゼット",
    is_shoes_storage: "下駄箱",
    is_maisonette: "メゾネット",
    is_new_construction: "新築",
    is_autolock: "オートロック",
    is_caretaker: "管理人有り",
    is_24hour_monitoring: "24時間有人管理",
    is_security_camera: "防犯カメラ",
    is_bathtoilet_separated: "バス・トイレ別",
    is_independent_washroom: "洗面所独立",
    is_reheating: "追焚付き",
    is_bathroom_dryer: "浴室乾燥機",
    is_wash_toilet_seat: "温水洗浄便座",
    is_top_floor: "最上階",
    is_second_floor_and_above: "2階以上",
    renovation_flg: "リノベーション",
    satellite_broadcasting: {
      1: "CSアンテナ",
      2: "BSアンテナ",
      3: "CS/BSアンテナ",
    },
    intercom_system: {
      1: "インターホン",
      2: "TVモニタ付きドアホン",
    },
    number_of_windows: {
      2: "2面採光",
      3: "3面採光",
    },
    bath_status: {
      1: "専用",
      2: "共同",
      3: "無",
    },
    toilet_status: {
      1: "専用",
      2: "共同",
      3: "無",
    },
    washing_machine_storage: {
      1: "室内",
      2: "室外",
      3: "あり",
    },
    internet_cost: {
      // 1: "実費",
      2: "インターネット無料",
    },
    furniture_appliance_status: [
      "家具家電",
      {
        1: "付き",
        2: "付き(オプション)",
        3: "なし",
      },
    ],
    air_conditioning_status: {
      0: "指定なし",
      1: "エアコン付き",
      2: "冷房のみ",
      3: "暖房のみ",
    },
  },
  offer: {
    is_freerent: "フリーレント",
    can_office_use: "SOHO・オフィス可",
    corporation: [
      "法人",
      {
        1: "限定",
      },
    ],
    student: [
      "学生",
      {
        1: "限定",
      },
    ],
    foreigner: [
      "外国人",
      {
        1: "限定",
        2: "不可",
        3: "相談",
      },
    ],
    gender: {
      1: "男女可",
      2: "男性限定",
      3: "女性限定",
    },
    children: [
      "子供",
      {
        1: "可",
        2: "不可",
        3: "相談",
      },
    ],
    pets: [
      "ペット",
      {
        1: "可",
        2: "不可",
        3: "相談可",
      },
    ],
    office_use: [
      "事務所利用",
      {
        1: "限定",
        2: "不可",
        3: "相談",
      },
    ],
    senior_citizens: [
      "高齢者",
      {
        1: "限定",
        2: "相談",
      },
    ],
    musical_instrument: [
      "楽器",
      {
        // 1: "限定",
        2: "不可",
        3: "相談可",
      },
    ],
    single: [
      "単身者",
      {
        1: "限定",
        2: "不可",
        3: "相談",
      },
    ],
    pair: [
      "二人入居",
      {
        // 1: "限定",
        2: "不可",
        3: "相談",
      },
    ],
    room_share: [
      "ルームシェア",
      {
        // 1: "限定",
        2: "不可",
        3: "可",
      },
    ],
    room_status: {
      1: "空室",
      2: "居住中",
      3: "賃貸中",
      4: "建設中",
      5: "要確認",
    },
    depreciation_deduction_type: {
      0: "未指定",
      1: "退去時",
      2: "毎年",
      3: "更新時",
    },
    card_payment_options: {
      1: "家賃カード決済可",
      2: "初期費用カード決済可",
      3: "家賃・初期費用カード決済可",
    },
  },
  search_room: {
    room_status: {
      1: "空室",
      2: "居住中",
      3: "賃貸中",
      4: "建設中",
      5: "要確認",
    },
    is_autolock: "オートロック",
    is_24hour_monitoring: "24時間有人管理",
    is_subscribed_security_service: "セキュリティ会社加入済",
    is_corner_room: "角部屋",
    is_top_floor: "最上階",
  },
  search_building: {
    is_exist_elevator: "エレベーター",
    is_exist_parcel_box: "宅配ボックス",
    is_exist_parking: "駐車場あり",
  },
} as const;

const viewText = (
  type: "room" | "building" | "offer" | "search_room" | "search_building",
  labelName: string,
  value: number | boolean,
): string | undefined => {
  const element = (
    Labels[type] as Record<
      string,
      string | (string | Record<string, string>)[] | Record<string, string>
    >
  )[labelName];

  if (typeof element === "string") {
    return Boolean(value) ? element : undefined;
  }
  if (Array.isArray(element)) {
    const prefix = element[0] as string;
    const variation = (element[1] as Record<string, string>)[String(value)];
    return !!variation ? `${prefix}${variation}` : undefined;
  }
  if (typeof element === "object") {
    const variation = element[String(value)];
    return variation || undefined;
  }
  return undefined;
};

export type RoomLabel = keyof (typeof Labels)["room"];
export type BuildingLabel = keyof (typeof Labels)["building"];
export type OfferLabel = keyof (typeof Labels)["offer"];
export type SearchRoomLabel = keyof (typeof Labels)["search_room"];
export type SearchBuildingLabel = keyof (typeof Labels)["search_building"];

export const viewRoomText = (labelName: RoomLabel, value: number | boolean): string | undefined => {
  return viewText("room", labelName, value);
};

export const viewBuildingText = (
  labelName: BuildingLabel,
  value: number | boolean,
): string | undefined => {
  return viewText("building", labelName, value);
};

export const viewOfferText = (
  labelName: OfferLabel,
  value: number | boolean,
): string | undefined => {
  return viewText("offer", labelName, value);
};

export const viewSearchRoomText = (
  labelName: SearchRoomLabel,
  value: number | boolean,
): string | undefined => {
  return viewText("search_room", labelName, value);
};

export const viewSearchBuildingText = (
  labelName: SearchBuildingLabel,
  value: number | boolean,
): string | undefined => {
  return viewText("search_building", labelName, value);
};
