"use client";

import { useState } from "react";
import styles from "./QA.module.scss";

interface qa {
  id: number;
  q: string;
  a: Array<string>;
}

const QAItem: React.FunctionComponent<{ qa: qa }> = (props) => {
  const [isOpened, setOpened] = useState(false);
  return (
    <dl
      onClick={() => setOpened(!isOpened)}
      className={`${styles.qaItem} ${isOpened ? styles.isOpened : ""}`}
      key={`qa_${props.qa.id}`}
    >
      <dt>
        <p>{props.qa.q}</p>
      </dt>
      <dd>
        <p>{props.qa.a}</p>
      </dd>
    </dl>
  );
};

export default QAItem;
