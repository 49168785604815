"use client";

import { useQuery } from "@apollo/client";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import BaseRoomCarousel from "@/common/partials/RoomCarousel/BaseRoomCarousel";
import { ViewHistory } from "~/db/models/ViewHistory";
import { GetSearchRoomsByIdQuery, GetSearchRoomsByIdDocument } from "~/generated/graphql";
import {
  AvailableStartDatePresenter,
  AvailableStartDatePresentable,
} from "~/types/Mixins/AvailableStartDatePresenter";
import { SearchBuilding } from "~/types/SearchBuildingType";
import { SearchRoom } from "~/types/SearchRoomType";
import { nonNullable } from "~/utils/typeUtil";

const SearchRoomPresenter = AvailableStartDatePresentable(SearchRoom);

type Props = {
  title?: JSX.Element;
  excludeRoomId?: number;
};

/** 表示する物件数 */
const DISPLAY_COUNT = 4;

/**
 * 閲覧履歴のカルーセルコンポーネント
 */
const ViewHistoryCarousel = (props: Props): JSX.Element => {
  const router = useRouter();

  const [viewHistories, setViewHistories] = useState<ViewHistory[]>([]);

  /**
   * 閲覧履歴を取得する
   */
  const getViewHistories = async () => {
    // NOTE: 成約済みの物件が含まれている可能性があるため50件分取得している
    setViewHistories(await ViewHistory.getLatest(50, [props.excludeRoomId].filter(nonNullable)));
  };

  /**
   * 閲覧履歴から部屋IDを取得する
   */
  const extractRoomIds = (viewHistories: ViewHistory[]) => {
    return viewHistories.map((h) => h.roomId);
  };

  /**
   * 物件データを作成する（閲覧日時の降順）
   */
  const buildRooms = (roomsData: GetSearchRoomsByIdQuery | undefined) => {
    if (!roomsData) return [];
    if (!roomsData.search_rooms?.data) return [];

    return roomsData.search_rooms.data
      .map((v) => {
        const room: SearchRoom & AvailableStartDatePresenter = new SearchRoomPresenter(
          v,
          new SearchBuilding({ ...v.building, rooms: [] }),
        );
        room.viewed_at = viewHistories.find((h) => h.roomId === Number(room.id))?.createdAt;

        return room;
      })
      .filter((v) => v.isViewingAvailable())
      .sort((a, b) => {
        const aViewedAtTime = a.viewed_at?.getTime() || 0;
        const bViewedAtTime = b.viewed_at?.getTime() || 0;
        return bViewedAtTime - aViewedAtTime;
      })
      .slice(0, DISPLAY_COUNT);
  };

  const { loading, error, data } = useQuery<GetSearchRoomsByIdQuery>(GetSearchRoomsByIdDocument, {
    variables: { roomIds: extractRoomIds(viewHistories) },
  });
  const rooms = buildRooms(data);

  useEffect(() => {
    getViewHistories();
  }, [router]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading || error || rooms.length === 0) {
    return <></>;
  }

  return <BaseRoomCarousel rooms={rooms} title={props.title} />;
};

export default ViewHistoryCarousel;
