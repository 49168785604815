import { SearchBuildingLabel, viewSearchBuildingText } from "./Labels/Label";
import {
  AvailableStartDatePresentable,
  AvailableStartDatePresenter,
} from "./Mixins/AvailableStartDatePresenter";
import { SearchRoom, SearchRoomProps } from "./SearchRoomType";
import { Transportation } from "./TransportationType";
import { isExistParkingValues } from "./values/parking";
import { LOCALIZE_MONTH_FORMAT } from "~/const/dayjs_format";
import dayjs from "~/lib/dayjs";
import { includes, nonNullable } from "~/utils/typeUtil";

const SearchRoomPresenter = AvailableStartDatePresentable(SearchRoom);

export class SearchBuilding {
  rooms: (SearchRoom & AvailableStartDatePresenter)[];
  transportations: Transportation[];

  id: string;
  building_name: string;
  prefecture_name?: string | null;
  city_name?: string | null;
  town_name?: string | null;
  street?: string | null;
  house_number?: string | null;

  is_condominium?: boolean;
  is_designers_mansion?: boolean;
  is_exist_elevator?: boolean | null;
  is_exist_parcel_box?: boolean | null;
  number_of_ground_floors?: number | null;
  construction_date?: string | null;
  private readonly building_age?: number | null;
  construction_name?: string | null;
  parking_status?: number | null;
  room_availability_updated_at: string;

  // 建物画像
  appearance_image_path: string;

  constructor(searchBuilding: {
    id: string;
    building_name?: string | null;
    prefecture_name?: string | null;
    city_name?: string | null;
    town_name?: string | null;
    street?: string | null;
    house_number?: string | null;
    is_condominium?: boolean | null;
    is_designers_mansion?: boolean | null;
    is_exist_elevator?: boolean | null;
    is_exist_parcel_box?: boolean | null;
    number_of_ground_floors?: number | null;
    construction_date?: string | null;
    building_age?: number | null;
    construction_name?: string | null;
    parking_status?: number | null;
    appearance_image_path?: string | null;
    bus_ride_time_1?: number | null;
    bus_ride_time_2?: number | null;
    bus_ride_time_3?: number | null;
    bus_route_name_1?: string | null;
    bus_route_name_2?: string | null;
    bus_route_name_3?: string | null;
    bus_stop_name_1?: string | null;
    bus_stop_name_2?: string | null;
    bus_stop_name_3?: string | null;
    walking_time_1?: number | null;
    walking_time_2?: number | null;
    walking_time_3?: number | null;
    railway_line_name_1?: string | null;
    railway_line_name_2?: string | null;
    railway_line_name_3?: string | null;
    station_name_1?: string | null;
    station_name_2?: string | null;
    station_name_3?: string | null;
    room_availability_updated_at: string;

    rooms?: NonNullable<SearchRoomProps>[];
  }) {
    this.id = searchBuilding.id;
    this.building_name = searchBuilding.building_name;
    this.prefecture_name = searchBuilding.prefecture_name;
    this.city_name = searchBuilding.city_name;
    this.town_name = searchBuilding.town_name;
    this.street = searchBuilding.street;
    this.house_number = searchBuilding.house_number;

    this.is_condominium = searchBuilding.is_condominium;
    this.is_designers_mansion = searchBuilding.is_designers_mansion;
    this.is_exist_elevator = searchBuilding.is_exist_elevator;
    this.is_exist_parcel_box = searchBuilding.is_exist_parcel_box;

    this.number_of_ground_floors = searchBuilding.number_of_ground_floors;
    this.construction_date = searchBuilding.construction_date;
    this.building_age = searchBuilding.building_age;
    this.construction_name = searchBuilding.construction_name;
    this.parking_status = searchBuilding.parking_status;
    this.room_availability_updated_at = searchBuilding.room_availability_updated_at;
    this.appearance_image_path = searchBuilding.appearance_image_path;

    // 交通情報
    this.transportations = [];
    ([1, 2, 3] as const).map(
      (i) =>
        (searchBuilding[`station_name_${i}`] || searchBuilding[`bus_route_name_${i}`]) &&
        this.transportations.push(
          new Transportation({
            bus_ride_time: searchBuilding[`bus_ride_time_${i}`],
            bus_stop_name: searchBuilding[`bus_stop_name_${i}`],
            walking_time: searchBuilding[`walking_time_${i}`],
            railway_line_name: searchBuilding[`railway_line_name_${i}`],
            station_name: searchBuilding[`station_name_${i}`],
          }),
        ),
    );

    this.rooms = searchBuilding.rooms
      ? searchBuilding.rooms.map((room) => new SearchRoomPresenter(room, this))
      : [];
  }

  get address(): string {
    return `${this.prefecture_name}${this.city_name}${this.town_name}${this.street}`;
  }

  get full_address(): string {
    return `${this.address}${this.house_number}`;
  }

  /**
   * 駐車場あり
   */
  get is_exist_parking(): boolean {
    return includes(isExistParkingValues, this.parking_status);
  }

  /**
   * 築年月
   */
  constructionYearMonth(): string {
    return dayjs(`${this.construction_date}`).format(LOCALIZE_MONTH_FORMAT);
  }

  youngBuildingAge(): boolean {
    if (this.building_age !== 0 && !this.building_age) return false;

    return this.building_age < 5;
  }

  buildingAgeText(): string {
    if (this.building_age === 0) return "新築";

    return `築${this.building_age}年`;
  }

  /**
   * 建物ラベル
   */
  get labels(): string[] {
    return [
      this.is_exist_parcel_box ? this.textOf("is_exist_parcel_box") : undefined,
      this.is_exist_parking ? this.textOf("is_exist_parking") : undefined,
      this.is_exist_elevator ? this.textOf("is_exist_elevator") : undefined,
    ].filter(nonNullable);
  }

  /**
   * @param {SearchBuildingLabel & keyof this} elementName SearchBuildingの要素名
   * @returns 要素名に対応したラベル
   */
  textOf(elementName: SearchBuildingLabel & keyof this): string | undefined {
    return viewSearchBuildingText(elementName, this[elementName]);
  }
}
