import { Offer } from "../OfferType";
import { LOCALIZE_DATE_FORMAT, LOCALIZE_MONTH_FORMAT } from "~/const/dayjs_format";
import dayjs from "~/lib/dayjs";

type Constructor<
  T = Pick<
    Offer,
    "type_of_occupancy" | "type_of_available_period" | "available_start_date_string" | "room_status"
  >,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
> = new (...args: any[]) => T;

export interface AvailableStartDatePresenter {
  availableStartDateText(): string;
  availableStartDateTextWithShortLabel(): string;
  isMoveInImmediately(): boolean;
  isMoveInConsultation(): boolean;
  isMoveInSpecified(): boolean;
  viewingStartDateText(): string;
  isViewingDateSpecified(): boolean;
  isViewingAvailable(): boolean;
  isRoomStatusUncertain(): boolean;
}

// FIXME: 返り値の型定義が必要（AvailableStartDatePresenterをimplementsしたTBaseであることを表現する必要あり）
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function AvailableStartDatePresentable<TBase extends Constructor>(Base: TBase): any {
  return class extends Base implements AvailableStartDatePresenter {
    /**
     * 入居可能日
     * @returns
     */
    availableStartDateText(): string {
      const typeOfAvailablePeriod = this.type_of_available_period;
      const availableStartDateString = this.available_start_date_string;

      if (this.isMoveInImmediately()) return "即入居可";
      if (this.isMoveInConsultation()) return "相談";

      const availableStartDate = dayjs(availableStartDateString);
      const period = {
        "1": "上旬",
        "2": "中旬",
        "3": "下旬",
      }[typeOfAvailablePeriod];

      if (period) {
        return `${availableStartDate.format(LOCALIZE_MONTH_FORMAT)}${period}`;
      }

      return availableStartDate.format(LOCALIZE_DATE_FORMAT);
    }

    availableStartDateTextWithShortLabel(): string {
      return [
        this.isMoveInConsultation() ? "入居可能日" : undefined,
        this.availableStartDateText(),
        this.isMoveInSpecified() ? "入居可" : undefined,
      ].join("");
    }

    /**
     * 内覧可能日
     * @description 正確な内覧可能日ではなく、「入居可能日」や現況からおおよその時期を表現する
     * @returns
     */
    viewingStartDateText(): string {
      if (this.isViewingAvailable()) return "現在見学可";
      if (this.isMoveInConsultation()) return "見学開始時期は要確認";
      if (this.type_of_available_period) {
        const period = {
          "1": { label: "下旬", subtract: 1 },
          "2": { label: "上旬", subtract: 0 },
          "3": { label: "中旬", subtract: 0 },
        }[this.type_of_available_period];

        return `${dayjs(this.available_start_date_string)
          .subtract(period.subtract, "month")
          .format(LOCALIZE_MONTH_FORMAT)}${period.label}頃`;
      }

      const date = dayjs(this.available_start_date_string);
      if (date.isBefore(dayjs().add(7, "day"))) return "数日以内に見学可能";

      return `${dayjs(this.available_start_date_string)
        .subtract(7, "day")
        .format(LOCALIZE_DATE_FORMAT)}頃`;
    }

    isViewingDateSpecified(): boolean {
      return this.viewingStartDateText().match(/^\d{4}年\d{1,2}月/) !== null;
    }

    /**
     * 現時点で見学可能かどうか
     */
    isViewingAvailable(): boolean {
      return this.isMoveInImmediately() || this.isRoomStatusEmpty();
    }

    /**
     * 即入居可能かどうか
     * @returns boolean
     */
    isMoveInImmediately(): boolean {
      if (this.type_of_occupancy === 1) return true;
      if (dayjs(this.available_start_date_string).isBefore(dayjs())) return true;

      return false;
    }

    /**
     * 入居時期相談かどうか
     * @returns
     */
    isMoveInConsultation(): boolean {
      return this.type_of_occupancy === 2;
    }

    /**
     * 入居時期が期日指定かどうか
     * @returns
     */
    isMoveInSpecified(): boolean {
      return !this.isMoveInImmediately() && !this.isMoveInConsultation();
    }

    /**
     * 現況：空室
     */
    private isRoomStatusEmpty(): boolean {
      return this.room_status === 1;
    }

    /**
     * 現況：要確認
     */
    isRoomStatusUncertain(): boolean {
      return this.room_status === 5;
    }
  };
}
