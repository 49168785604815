import { PriceInterface } from "./Interfaces/PriceInterface";

export class BrokerFeeType implements PriceInterface {
  private readonly _price: number;
  private readonly _rent: number;

  constructor(p: { rent: number; price: number }) {
    this._rent = p.rent;
    this._price = p.price;
  }

  isInitialAmountCost(): boolean {
    return true;
  }

  isMonthlyCost(): boolean {
    return false;
  }

  isLeavingCost(): boolean {
    return false;
  }

  isFree(): boolean {
    return this._price === 0;
  }

  priceLabel(): string {
    if (this.isFree()) return "無料";

    // NOTE: 仲介手数料は四捨五入すると上限である家賃の1ヶ月以上を超える可能性があるので、100の位を切り捨てして表示する
    return `${Math.floor(this._price / 1000) / 10}万円`;
  }

  pricePerMonthLabel(): string {
    const rentFeeWithTax = (this._rent * 11) / 10;
    if (rentFeeWithTax === this._price) return "1.1ヶ月";

    return this.priceLabel();
  }

  get name(): string {
    return "仲介手数料";
  }

  get price(): number {
    return this._price;
  }

  get price_text(): string {
    return this.isFree() ? "0円" : `${this._price.toLocaleString()}円`;
  }
}
