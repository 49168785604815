export const ParkingStatus = {
  Undefined: "undefined", // 指定なし
  None: "none", // なし
  NoCharge: "noCharge", // 付無料
  Exist: "exist", // 有（敷地内）
  Neighborhood: "neighborhood", // 近隣駐車場
  NotAvailable: "notAvailable", // 空きなし
} as const;

export type ParkingStatus = (typeof ParkingStatus)[keyof typeof ParkingStatus];
