"use client";

import { Sidetab } from "@typeform/embed-react";
import { usePathname, useSearchParams } from "next/navigation";

const SurveySideTab: React.FunctionComponent = () => {
  const searchParams = useSearchParams();
  const path = usePathname();
  const queryString = searchParams.toString();
  const pathName = queryString ? `${path}?${queryString}` : path;
  return false ? (
    <Sidetab
      id="CW1807Ib"
      buttonColor="#2391DC"
      customIcon="https://images.typeform.com/images/bMzQC9pButCX"
      buttonText="ユーザーアンケート"
      open="exit"
      hidden={{
        utm_source: `${process.env.NEXT_PUBLIC_FRONT_URL + pathName}`,
        utm_campaign: "side_tab",
      }}
    ></Sidetab>
  ) : null;
};

export default SurveySideTab;
