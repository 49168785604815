import Link from "next/link";
import styles from "./RoomCard.module.scss";
import { FavoritePictButton } from "@/common/buttons/FavoriteButton/FavoritePictButton";
import { InitialCostScore } from "@/common/icons/InitialCostScore/InitialCostScore";
import Image from "@/common/partials/Image/ImageWithNoImage";
import UpdatePoint from "@/common/partials/UpdatePoint/UpdatePoint";
import PurpleStarsIcon from "@/pages/list/svgs/purpleStars.svg";
import dayjs from "~/lib/dayjs";
import { AvailableStartDatePresenter } from "~/types/Mixins/AvailableStartDatePresenter";
import { SearchRoom } from "~/types/SearchRoomType";

/**
 * 物件情報の概要が記載されたカードUIのコンポーネント
 */
const RoomCard: React.FunctionComponent<{
  room: SearchRoom & AvailableStartDatePresenter;
  isRecommendMark?: boolean;
  size?: "default" | "small" | "variable";
}> = ({ room, size, isRecommendMark }) => {
  let sizeClass: string;
  switch (size) {
    case "variable":
      sizeClass = styles.variable;
      break;
    case "small":
      sizeClass = styles.small;
      break;
    default:
      sizeClass = styles.default;
      break;
  }

  const featuresText = [
    room.room_number ? `${room.room_number}号室` : `${room.location_floor}階`,
    room.floor_plan,
    `${room.square_meters}㎡`,
    room.direction,
  ].join(" / ");

  return (
    <div className={`${styles.roomCard} ${sizeClass}`}>
      <FavoritePictButton
        roomId={Number(room.id)}
        roomUrl={`${process.env.NEXT_PUBLIC_FRONT_URL}/detail/${room.building.id}/${room.id}`}
        roomTitle={room.building.building_name}
        roomNumber={room.room_number}
        roomArea={room.building.address}
        className={styles.favorite}
      ></FavoritePictButton>
      <div className={styles.infoTagWrap}>
        {isRecommendMark && (
          <div>
            <span className={styles.recommend}>
              <PurpleStarsIcon />
              <span>提案</span>
            </span>
          </div>
        )}
        {dayjs(room.availability_updated_at).isAfter(dayjs().subtract(7, "d")) && (
          <span className={styles.newMark}>{`${dayjs(
            room.availability_updated_at,
          ).fromNow()}公開`}</span>
        )}
      </div>

      <Link
        className={styles.mainLink}
        href={`/detail/${room.building.id}/${room.id}`}
        target="_blank"
        prefetch={false}
      >
        <div className={styles.imageWrapper}>
          <Image
            src={`${process.env.NEXT_PUBLIC_IMAGE_URL}${room.main_image_path}`}
            fill
            style={{ objectFit: "cover", objectPosition: "center" }}
            alt={room.room_name}
            unoptimized
          ></Image>
        </div>
        <div className={styles.contentWrapper}>
          <p className={styles.price}>
            <span className={styles.priceMain}>{room.rent.price_text}</span>
            <span className={styles.priceSub}>
              <span className="is-pc-only">管理費 {room.administration_fee.price_text}</span>
              <span className="is-sp-only">({room.administration_fee.price_text})</span>
            </span>
          </p>
          <ul className={styles.moneys}>
            <li className={room.security_deposit.isFree() ? styles.isFree : ""}>
              {room.security_deposit.pricePerMonthLabel()}
            </li>
            <li className={room.key_money.isFree() ? styles.isFree : ""}>
              {room.key_money.pricePerMonthLabel()}
            </li>
            <li className={room.broker_fee.isFree() ? styles.isFree : ""}>
              {room.broker_fee.pricePerMonthLabel()}
            </li>
          </ul>
          <p className={styles.text}>
            {room.freerentLabel ? (
              <span className={styles.freerentTag}>{room.freerentLabel}</span>
            ) : null}
          </p>
          {room.initial_cost_score !== 0 && (
            <div className={styles.initialCostWrapper}>
              <InitialCostScore rank={room.initial_cost_score}></InitialCostScore>
            </div>
          )}
          <p className={styles.text}>{featuresText}</p>
          <p className={`${styles.text} is-pc-only`}>
            {room.building.transportations[0]?.overview() || "-"}
          </p>
          <p className={`${styles.text} is-sp-only`}>
            {room.building.transportations[0]?.overview(false) || "-"}
          </p>

          <p className={styles.text}>
            <span className={room.isViewingAvailable() ? styles.emphasize : ""}>
              {room.isViewingAvailable()
                ? "現在見学可"
                : room.isRoomStatusUncertain()
                  ? "現況要確認"
                  : room.textOf("room_status") || "-"}
            </span>
            {" / "}
            <span className={room.isMoveInImmediately() ? styles.emphasize : ""}>
              {room.availableStartDateTextWithShortLabel() || "-"}
            </span>
          </p>
          <p className={styles.text}>
            <UpdatePoint></UpdatePoint>
            {room.updated_at ? dayjs(room.updated_at).fromNow() + "更新" : "-"}
          </p>
        </div>
      </Link>

      <hr className={styles.divider} />

      <div className={styles.footer}>
        <Link
          className={styles.footerLink}
          href={`/building/${room.building.id}`}
          prefetch={false}
          target="_blank"
        >
          <span className={styles.footerLinkIcon}></span>
          <span className={styles.footerLinkText}>{room.building.building_name}</span>
        </Link>
      </div>
    </div>
  );
};

export default RoomCard;
