import { StringSearchQuery } from "~/types/QueryType";

/**
 * Article: 特集
 *
 * エリア系以外の単一もしくは複数の検索条件をまとめたもの.
 * TOP画面でのおすすめカテゴリーとGoogleの検索用として使用される.
 * ex）「敷礼金無料」 = 検索カテゴリ「敷金無料」+「礼金無料」
 *
 * @param {string} ID 特集ID
 * @param {string} display_name 表示する名称
 * @param {string|undefined} seo_title metaタグや検索一覧のタイトルに使用する名称
 * @param {string} img_url TOP画面のおすすめカテゴリーに表示する背景画像のURL
 * @param {StringSearchQuery} search_query 検索条件クエリ
 * @param {boolean} is_display TOP画面のおすすめカテゴリーへの表示制御
 */
type ArticleProps = {
  id: string;
  display_name: string;
  seo_title: string | undefined;
  search_query: StringSearchQuery;
  img_url?: string | undefined;
  is_display: boolean;
};

export class Article {
  id: string;
  display_name: string;
  search_query: StringSearchQuery;
  img_url: string;
  is_display: boolean;
  private _seo_title: string | undefined;

  constructor(article: ArticleProps) {
    if (article.is_display && !article.img_url) {
      throw new Error("The img_url is required when is_display is true!");
    }

    this.id = article.id;
    this.display_name = article.display_name;
    this._seo_title = article.seo_title;
    this.img_url = article.img_url || "";
    this.search_query = article.search_query;
    this.is_display = article.is_display;
  }

  static find(id: string): Article | undefined {
    return articles.find((a) => a.id == id);
  }

  get seo_title(): string {
    return this._seo_title ?? this.display_name;
  }
}

/**
 * NOTE
 *  - エリア系の条件には対応していない
 *  - サイトマップへの追加条件としても利用される
 *    - 変更や項目の追加を行う際は、sitemap/Logic/SitemapFieldBuilder/BaseRoomListSitemapFieldBuilder を併せて更新する必要がある
 */
export const articles = [
  {
    id: "0",
    display_name: "敷金礼金なし",
    seo_title: "敷金礼金なし(0円・ゼロ)",
    img_url: "/static/images/articles/no-deposit-key-money.png",
    search_query: { ca: "d-0-1" },
    is_display: true,
  },
  {
    id: "2",
    display_name: "仲介手数料無料",
    seo_title: "仲介手数料無料(0円・ゼロ)",
    img_url: "/static/images/articles/no-broker-fee.png",
    search_query: { ca: "d-25" },
    is_display: true,
  },
  {
    id: "3",
    display_name: "初期費用分割OK",
    seo_title: undefined,
    search_query: { ca: "d-26" },
    is_display: false,
  },
  {
    id: "4",
    display_name: "新築・築浅",
    seo_title: undefined,
    img_url: "/static/images/articles/new.png",
    search_query: { ca: "d-27" },
    is_display: true,
  },
  {
    id: "6",
    display_name: "分譲マンション",
    seo_title: undefined,
    img_url: "/static/images/articles/subdivision.png",
    search_query: { ca: "d-4" },
    is_display: true,
  },
  {
    id: "7",
    display_name: "デザイナーズ",
    seo_title: "デザイナーズ(おしゃれ物件)",
    img_url: "/static/images/articles/designers.png",
    search_query: { ca: "d-5" },
    is_display: true,
  },
  {
    id: "8",
    display_name: "タワーマンション",
    seo_title: "タワーマンション(高層マンション)",
    img_url: "/static/images/articles/tower-mansion.png",
    search_query: { ca: "d-21" },
    is_display: true,
  },
  {
    id: "9",
    display_name: "低層マンション",
    seo_title: undefined,
    img_url: "/static/images/articles/low-rise.png",
    search_query: { ca: "d-22" },
    is_display: true,
  },
  {
    id: "10",
    display_name: "ペット相談可能",
    seo_title: "ペット可(相談可)",
    img_url: "/static/images/articles/pet.png",
    search_query: { ca: "d-24" },
    is_display: true,
  },
  {
    id: "11",
    display_name: "家具家電付き",
    seo_title: undefined,
    img_url: "/static/images/articles/furniture-appliance.png",
    search_query: { ca: "d-6" },
    is_display: true,
  },
  {
    id: "12",
    display_name: "敷金なし",
    seo_title: "敷金なし(0円・ゼロ)",
    search_query: { ca: "d-0" },
    is_display: false,
  },
  {
    id: "13",
    display_name: "礼金なし",
    seo_title: "礼金なし(0円・ゼロ)",
    search_query: { ca: "d-1" },
    is_display: false,
  },
  {
    id: "14",
    display_name: "インターネット無料",
    seo_title: "インターネット無料(Wi-Fi付)",
    search_query: { ca: "d-23" },
    is_display: false,
  },
  {
    id: "15",
    display_name: "一人暮らし",
    seo_title: "一人暮らし(単身)",
    search_query: { fp: "d-1_r-1_k-1_dk-1_ldk" },
    is_display: false,
  },
  {
    id: "16",
    display_name: "二人暮らし",
    seo_title: "二人暮らし(同棲・新婚・カップル)",
    search_query: { fp: "d-1_ldk-2_k-2_dk-2_ldk-3_k-3_dk-3_ldk" },
    is_display: false,
  },
  {
    id: "17",
    display_name: "ファミリー向け",
    seo_title: "ファミリー(家族)向け",
    search_query: { fp: "d-2_ldk-3_k-3_dk-3_ldk-4_more" },
    is_display: false,
  },
  {
    id: "18",
    display_name: "高級賃貸",
    seo_title: "高級賃貸マンション",
    img_url: "/static/images/articles/luxury.png",
    search_query: { lr: "150000", ur: "" },
    is_display: true,
  },
  {
    id: "19",
    display_name: "リノベーション",
    seo_title: "リノベーション・リフォーム",
    search_query: { ca: "d-9" },
    is_display: false,
  },
  {
    id: "20",
    display_name: "即入居可",
    seo_title: "即入居可(即引き渡し可)",
    search_query: { ca: "d-50" },
    is_display: false,
  },
  {
    id: "21",
    display_name: "見学可",
    seo_title: undefined,
    search_query: { ca: "d-61" },
    is_display: false,
  },
  {
    id: "22",
    display_name: "SOHO・オフィス",
    seo_title: undefined,
    img_url: "/static/images/articles/soho.png",
    search_query: { ca: "d-60" },
    is_display: true,
  },
  {
    id: "23",
    display_name: "フリーレント",
    seo_title: "フリーレント(一定家賃無料)",
    img_url: "/static/images/articles/free-rent.png",
    search_query: { ca: "d-28" },
    is_display: true,
  },

  // 家賃条件（id としては 100~120 を確保しておけば大丈夫そう）
  {
    id: "100",
    display_name: "5万円以下",
    seo_title: undefined,
    search_query: { lr: "", ur: "50000" },
    is_display: false,
  },
  {
    id: "101",
    display_name: "5〜6万円",
    seo_title: undefined,
    search_query: { lr: "50000", ur: "60000" },
    is_display: false,
  },
  {
    id: "102",
    display_name: "6〜7万円",
    seo_title: undefined,
    search_query: { lr: "60000", ur: "70000" },
    is_display: false,
  },
  {
    id: "103",
    display_name: "7〜8万円",
    seo_title: undefined,
    search_query: { lr: "70000", ur: "80000" },
    is_display: false,
  },
  {
    id: "104",
    display_name: "8〜9万円",
    seo_title: undefined,
    search_query: { lr: "80000", ur: "90000" },
    is_display: false,
  },
  {
    id: "105",
    display_name: "9〜10万円",
    seo_title: undefined,
    search_query: { lr: "90000", ur: "100000" },
    is_display: false,
  },
  {
    id: "106",
    display_name: "10万円以上",
    seo_title: undefined,
    search_query: { lr: "100000", ur: "" },
    is_display: false,
  },
].map((a) => new Article(a));
