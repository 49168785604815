import styles from "./RoomCarousel.module.scss";
import RoomCard from "@/common/partials/RoomCard/RoomCard";
import { AvailableStartDatePresenter } from "~/types/Mixins/AvailableStartDatePresenter";
import { SearchRoom } from "~/types/SearchRoomType";

const BaseRoomCarousel: React.FunctionComponent<{
  rooms: (SearchRoom & AvailableStartDatePresenter)[];
  title?: JSX.Element;
}> = ({ rooms, title }) => {
  return (
    <>
      <div>{title || ""}</div>
      <div className={styles.roomCarouselContent}>
        {rooms.map((room, i) => (
          <div
            key={`${i}_${room.building.id}_${room.id}`}
            className={styles.roomCarouselContentItem}
          >
            <RoomCard room={room}></RoomCard>
          </div>
        ))}
      </div>
    </>
  );
};

export default BaseRoomCarousel;
