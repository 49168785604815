import { PriceInterface } from "./Interfaces/PriceInterface";

export const PRICE_TYPE = {
  InitialCost: 1,
  MonthlyCost: 2,
  LeavingCost: 3,
  ForRefund: 4, // 返金に関わる金額項目
} as const;
export type PriceType = (typeof PRICE_TYPE)[keyof typeof PRICE_TYPE];

export class Price implements PriceInterface {
  private readonly _name: string;
  private readonly _price: number;
  private readonly _rent: number;
  private readonly _class: (typeof PRICE_TYPE)[keyof typeof PRICE_TYPE];

  constructor(p: { name: string; rent: number; class: PriceType; unit: number; price: number }) {
    this._name = p.name;
    this._rent = p.rent;
    this._class = p.class;

    // 1: ヶ月, 2: 円
    if (p.unit === 1) {
      this._price = p.price * p.rent;
    } else {
      this._price = p.price;
    }
  }

  /**
   * 初期費用 or 月額費用
   */
  isInitialAmountCost(): boolean {
    return this.isInitialCost() || this.isMonthlyCost();
  }

  /**
   * 初期費用系
   */
  private isInitialCost(): boolean {
    return this?._class === PRICE_TYPE.InitialCost;
  }

  /**
   * 月額費用系
   */
  isMonthlyCost(): boolean {
    return this?._class === PRICE_TYPE.MonthlyCost;
  }

  /**
   * 退去時費用系
   */
  isLeavingCost(): boolean {
    return this?._class === PRICE_TYPE.LeavingCost;
  }

  /**
   * 無料かどうかの判定
   */
  isFree(): boolean {
    return this._price === 0;
  }

  /**
   * 料金（ラベル系で使われる）
   *
   * 無料の場合 "0円" ではなく "無料"と表現する。
   */
  priceLabel(): string {
    if (this._price === 0) return "無料";

    return `${(this._price / 10000).toFixed(1)}万円`;
  }

  /**
   * 料金の月単位表現（ラベル系で使われる）
   *
   * 割り切れない場合は金額で表示する。
   * 無料の場合 "0円" ではなく "無料"と表現する。
   */
  pricePerMonthLabel(): string {
    if (this._price === 0) return "無料";
    if ((this._price * 10) % this._rent === 0) return `${this._price / this._rent}ヶ月`;

    return this.priceLabel();
  }

  get price_text(): string {
    return this._price ? this._price.toLocaleString() + "円" : "0円";
  }

  get name(): string {
    return this._name;
  }

  get price(): number {
    return this._price;
  }
}
