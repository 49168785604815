import { cloneDeep } from "lodash-es";
import { BrokerFeeType } from "./BrokerFeeType";
import { PriceInterface } from "./Interfaces/PriceInterface";
import { SearchRoomLabel, viewSearchRoomText } from "./Labels/Label";
import { PRICE_TYPE, Price } from "./PriceType";
import { SearchBuilding } from "./SearchBuildingType";
import { FavoriteRoom } from "~/db/models/FavoriteRoom";
import dayjs from "~/lib/dayjs";

export type SearchRoomProps = {
  id: string;
  room_number?: string | null;
  room_number_public?: boolean | null;
  location_floor?: string | null;
  square_meters?: number | null;
  room_count?: number | null;
  room_created_at?: string | null;
  room_updated_at?: string | null;
  company_management_number?: string | null;
  direction_id?: number | null;
  direction?: string | null;
  floor_plan_name?: string | null;
  rent?: number | null;
  administration_fee?: number | null;
  key_money?: number | null;
  key_money_unit?: number | null;
  security_deposit?: number | null;
  security_deposit_unit?: number | null;
  deposit?: number | null;
  deposit_unit?: number | null;
  broker_fee: number;
  rent_administration_fee?: number | null;
  internet_cost?: number | null;
  type_of_contract?: number | null;
  advertisement_rate?: number | null;
  pets?: number | null;
  furniture_appliance_status?: number | null;
  renovation_flg?: boolean | null;
  interior_image_path?: string | null;
  floor_plan_image_path?: string | null;
  type_of_occupancy?: number | null;
  type_of_available_period?: number | null;
  available_date?: string | null;
  available_start_date_text?: string | null;
  room_status?: number | null;
  is_ready_to_preview?: boolean | null;
  is_autolock?: boolean | null;
  is_24hour_monitoring?: boolean | null;
  is_top_floor?: boolean | null;
  is_corner_room?: boolean | null;
  is_freerent?: boolean | null;
  freerent_available_months?: number | null;
  popularity_score?: number | null;
  initial_cost_score: number;
  estate_agent_name?: string | null;
  recommendation_comment?: string | null;
  availability_updated_at: string;
  room?: {
    direction?: { direction?: string | null } | null;
    reservation_information?: {
      type_of_occupancy?: number | null;
      type_of_available_period?: number | null;
      available_start_date?: string | null;
    } | null;
    room_equipment?: {
      is_subscribed_security_service?: boolean | null;
    } | null;
    estate_agent: { recommendation_comment_public: boolean };
  } | null;
};

export class SearchRoom {
  roomData: NonNullable<SearchRoomProps>;
  building: SearchBuilding;
  readonly broker_fee: PriceInterface;

  id: string;
  room_name: string;
  location_floor: string;
  main_image_path: string;
  interior_image_path: string;
  floor_plan_image_path: string;
  room_count: number;
  floor_plan: string;
  square_meters: number;
  advertisement_rate: number;
  internet_cost: number;
  original_location_floor: string;
  room_number: string;
  room_number_public: boolean;
  direction: string;
  is_top_floor: boolean;
  is_corner_room: boolean;
  is_freerent: boolean;
  room_status: number;
  popularity_score: number;
  initial_cost_score: number;
  pets?: number;
  furniture_appliance_status?: number;
  renovation_flg?: boolean;
  company_name: string;
  recommendation_comment: string;
  recommendation_comment_public: boolean;
  availability_updated_at: Date;
  created_at: Date;
  updated_at: Date;

  // 入居可能日関連の項目
  type_of_occupancy: number;
  type_of_available_period: number;
  /** 入居開始可能日 */
  available_start_date_string: string | undefined;
  /** 入居開始可能日（即入居可など意味のあるテキスト） */
  available_start_date_text?: string | null;
  is_ready_to_preview: boolean;

  // 設備（セキュリティ）
  is_autolock?: boolean;
  is_24hour_monitoring?: boolean;
  is_subscribed_security_service?: boolean;

  rent: PriceInterface;
  administration_fee: PriceInterface;
  administration_fee_price: number;
  security_deposit: PriceInterface;
  security_deposit_unit: number;
  security_deposit_price: number;
  deposit?: PriceInterface;
  key_money: PriceInterface;
  key_money_price: number;
  key_money_unit: number;
  broker_fee_price: number;
  viewed_at?: Date;

  // お気に入り
  favorite?: FavoriteRoom;

  private freerent_available_months: number;

  constructor(r: NonNullable<SearchRoomProps>, building: SearchBuilding) {
    this.roomData = r;
    this.building = building;

    this.id = r.id;
    this.location_floor = r.location_floor;
    this.interior_image_path = r.interior_image_path;
    this.main_image_path = this.interior_image_path || this.building?.appearance_image_path;
    this.floor_plan_image_path = r.floor_plan_image_path;
    this.floor_plan = r.floor_plan_name;
    this.room_count = r.room_count;
    this.floor_plan = this.room_count + r.floor_plan_name;
    this.square_meters = r.square_meters;
    this.advertisement_rate = r.advertisement_rate;
    this.internet_cost = r.internet_cost;
    this.original_location_floor = r.location_floor;
    this.room_number = r.room_number;
    this.room_number_public = r.room_number_public;

    this.company_name = r.estate_agent_name || "";
    this.direction = r.direction;

    this.is_top_floor = r.is_top_floor;
    this.is_corner_room = r.is_corner_room;
    this.is_freerent = r.is_freerent;
    this.room_status = r.room_status;
    this.freerent_available_months = r.freerent_available_months;
    this.room_name = this.building
      ? this.building.building_name + (r.room_number_public ? this.room_number : "")
      : "";
    this.popularity_score = r.popularity_score;
    this.initial_cost_score = r.initial_cost_score;
    this.pets = r.pets;
    this.furniture_appliance_status = r.furniture_appliance_status;
    this.renovation_flg = r.renovation_flg;
    this.availability_updated_at = dayjs.tz(r.availability_updated_at).toDate();
    this.created_at = dayjs.tz(r.room_created_at).toDate();
    this.updated_at = dayjs.tz(r.room_updated_at).toDate();

    this.type_of_occupancy = r.type_of_occupancy;
    this.type_of_available_period = r.type_of_available_period;
    this.available_start_date_string = r.available_date;
    this.available_start_date_text = r.available_start_date_text;
    this.is_ready_to_preview = r.is_ready_to_preview;

    this.is_autolock = r.is_autolock;
    this.is_24hour_monitoring = r.is_24hour_monitoring;
    this.is_subscribed_security_service = r.room?.room_equipment?.is_subscribed_security_service;

    this.recommendation_comment = r.recommendation_comment || "";
    this.recommendation_comment_public =
      r.room?.estate_agent?.recommendation_comment_public || false;

    this.administration_fee_price = r.administration_fee;
    this.security_deposit_unit = r.security_deposit_unit;
    this.security_deposit_price = r.security_deposit;
    this.key_money_unit = r.key_money_unit;
    this.key_money_price = r.key_money;
    this.broker_fee_price = r.broker_fee;

    /*====================*
     * Price型Objectの生成 *
     *====================*/

    if (typeof r.rent === "number")
      this.rent = new Price({
        name: "賃料",
        price: r.rent,
        unit: 2, // 円
        class: PRICE_TYPE.MonthlyCost,
        rent: r.rent,
      });

    if (typeof r.administration_fee === "number")
      this.administration_fee = new Price({
        name: "管理費",
        price: r.administration_fee,
        unit: 2, // 円
        class: PRICE_TYPE.MonthlyCost,
        rent: r.rent,
      });

    if (typeof r.security_deposit === "number")
      this.security_deposit = new Price({
        name: "敷金",
        price: r.security_deposit,
        unit: r.security_deposit_unit,
        class: PRICE_TYPE.InitialCost,
        rent: r.rent,
      });

    if (typeof r.deposit === "number")
      this.deposit = new Price({
        name: "保証金",
        price: r.deposit,
        unit: r.deposit_unit,
        rent: r.rent,
        class: PRICE_TYPE.InitialCost,
      });

    if (typeof r.key_money === "number")
      this.key_money = new Price({
        name: "礼金",
        price: r.key_money,
        unit: r.key_money_unit,
        class: PRICE_TYPE.InitialCost,
        rent: r.rent,
      });

    if (typeof r.broker_fee === "number") {
      this.broker_fee = new BrokerFeeType({
        price: r.broker_fee,
        rent: this.rent.price,
      });
    }
  }

  /**
   * お気に入り物件の判定
   */
  get isFavorite(): boolean {
    return !!this.favorite?.createdAt;
  }

  get labels(): string[] {
    return [
      this.is_corner_room ? "角部屋" : undefined,
      this.is_top_floor ? "最上階" : undefined,
    ].filter((v) => !!v);
  }

  get freerentLabel(): string {
    return this.is_freerent
      ? this.freerent_available_months >= 1
        ? `フリーレント${this.freerent_available_months}ヶ月(${
            (this.freerent_available_months * this.rent.price) / 10000
          }万円お得)`
        : `フリーレントあり`
      : undefined;
  }
  /**
   * @param {SearchRoomLabel & keyof this} elementName SearchRoomの要素名
   * @returns 要素名に対応したラベル
   */
  textOf(elementName: SearchRoomLabel & keyof this): string | undefined {
    return viewSearchRoomText(elementName, this[elementName]);
  }

  /**
   * インスタンスの複製をディープコピーで作成する
   */
  clone(): this {
    return cloneDeep(this);
  }
}
