// 便利な型を定義する

/** オブジェクトのvalueのユニオン型を作る */
export type valueOf<T> = T[keyof T];

/**
 * 型情報からも nullable な要素を除去するフィルタ関数
 *
 * [使用方法]
 * ```
 * arr.filter(nonNullable);
 * ```
 */
export const nonNullable = <T>(value: T): value is NonNullable<T> => value != null;

/**
 * ある要素が配列の要素の一つであることをチェックする Type Guard
 */
export function includes<T extends U, U>(collection: ReadonlyArray<T>, element: U): element is T {
  return collection.includes(element as T);
}

export type Maybe<T> = T | null;
