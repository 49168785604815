import Station from "./route/StationType";

export class Transportation {
  bus_ride_time: number;
  bus_stop_name?: string;
  walking_time: number;
  station_name?: string;
  railway_line_name?: string;
  homes_station_code?: string;
  homes_railway_line_code?: string;
  station?: Station;

  constructor(p: {
    bus_ride_time: number;
    bus_stop_name?: string;
    station_name?: string;
    railway_line_name?: string;
    homes_station_code?: string;
    homes_railway_line_code?: string;
    walking_time: number;
  }) {
    this.bus_ride_time = p.bus_ride_time;
    this.bus_stop_name = p.bus_stop_name;
    this.station_name = p.station_name;
    this.railway_line_name = p.railway_line_name;
    this.homes_station_code = p.homes_station_code;
    this.homes_railway_line_code = p.homes_railway_line_code;
    this.walking_time = p.walking_time;
    this.station = Station.find("" + this.homes_railway_line_code + this.homes_station_code);
  }

  public overview(withRailwayName = true): string {
    return [
      this.isStationSpecified() && withRailwayName ? this.railway_line_name : undefined,
      this.isStationSpecified() ? `${this.station_name}駅` : undefined,
      this.isBusSpecified()
        ? this.bus_ride_time > 0
          ? `バス${this.bus_ride_time}分 ${this.bus_stop_name}下車`
          : `バス停${this.bus_stop_name}`
        : undefined,
      (this.isStationSpecified() || this.isBusSpecified()) && this.walking_time > 0
        ? `徒歩${this.walking_time}分`
        : undefined,
    ]
      .filter(Boolean)
      .join(" ");
  }

  public isExistStation(): boolean {
    return !!this.station && !!this.station.line;
  }

  public isExistBusStop(): boolean {
    return !!this.bus_stop_name;
  }

  private isStationSpecified(): boolean {
    return (
      !!this.station_name && this.station_name !== "未設定" && this.station_name !== "＊＊＊＊"
    );
  }

  private isBusSpecified(): boolean {
    return !!this.bus_stop_name;
  }
}
